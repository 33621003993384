export default (theme) => {
  const {
    breakpoints,
  } = theme

  return {
    container: {
      marginTop: '4rem',
      marginBottom: '4rem',
    },

    formControl: {
      marginBottom: '1.5rem',
      [breakpoints.down('sm')]: {
        marginBottom: '0.5rem',
      },

      '& label': {
        [breakpoints.down('sm')]: {
          fontSize: '0.8rem',
        },
      },
    },

    typography: {
      maxWidth: '34rem',
      fontWeight: 'bold',
      margin: 'auto',
      [breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },

    textField: {
      marginTop: '0.5rem',
    },

    button: {
      minWidth: '12.5rem',
      marginTop: '1.5rem',
    },

    wrapperButton: {
      display: 'flex',
      justifyContent: 'center',
    },

    wrapperForm: {
      padding: '2.5rem',
      borderRadius: '1rem',
    },

    alert: {
      marginTop: '1.5rem',
    },
  }
}

import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import qs from 'qs'
import {
  Container,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Button,
  Card,
  Grid,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

const POST_URL = 'https://script.google.com/macros/s/AKfycbxK5Bge-ffRQZqWiOiunxbzo3wINsrf6r9I5WiwMB1wlGWS8keCV0YjgA/exec'

const Contact = () => {
  const classes = useStyles()
  const { control, handleSubmit } = useForm()
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onSubmit = async (formData) => {
    if (formData.honeypot) return // bot checker

    // check if data present
    if (!formData.name || !formData.email || !formData.message) return

    setLoading(true)
    try {
      const response = await axios({
        method: 'post',
        url: POST_URL,
        data: qs.stringify({
          ...formData,
          formGoogleSendEmail: 'sales@majin.land',
          formSubjectPrefix: 'Majin enquiry from ',
          formGoogleSheetName: 'majin',
          formDataNameOrder: JSON.stringify([
            'name',
            'company',
            'email',
            'whereFindUs',
            'message',
          ]),
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      })
      setSubmitted((response && response.status === 200))
    } catch (error) {
      // TODO: use error notification bar
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderSubmitted = () => {
    return (
      <Alert
        severity="success"
        className={classes.alert}
      >
        Terima kasih, Tim kami akan segera hubungi Anda. Pastikan email yang Anda gunakan benar
      </Alert>
    )
  }

  const renderForm = () => {
    return (
      <>
        <Typography
          id="contact"
          variant="h5"
          align="center"
          className={classes.typography}
        >
          Hubungi kami untuk konsultasikan
          kebutuhan desain Perusahaan Anda !
        </Typography>
        <Card className={classes.wrapperForm}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              defaultValue=""
              render={({ onChange, value }) => (
                <input
                  type="text"
                  onChange={onChange}
                  value={value}
                  style={{ display: 'none' }}
                />
              )}
              name="honeypot"
              control={control}
            />
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Controller
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                    >
                      <FormLabel>Nama</FormLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        className={classes.textField}
                      />
                    </FormControl>
                  )}
                  name="name"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Controller
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                    >
                      <FormLabel>Perusahaan</FormLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        className={classes.textField}
                      />
                    </FormControl>
                  )}
                  name="company"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Controller
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                    >
                      <FormLabel>Email</FormLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        className={classes.textField}
                      />
                    </FormControl>
                  )}
                  name="email"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Controller
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                    >
                      <FormLabel>
                        Dimana Anda mengetahui tentang Majin?
                      </FormLabel>
                      <TextField
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        className={classes.textField}
                      />
                    </FormControl>
                  )}
                  name="whereFindUs"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Controller
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <FormLabel>Pesan</FormLabel>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={6}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        className={classes.textField}
                      />
                    </FormControl>
                  )}
                  name="message"
                  control={control}
                />
              </Grid>
            </Grid>
            <div className={classes.wrapperButton}>
              {/* TODO: Add loading animation */}
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isLoading}
              >
                Kirim
              </Button>
            </div>
          </form>
        </Card>
      </>
    )
  }

  return (
    <Container
      maxWidth="lg"
      className={classes.container}
    >
      {isSubmitted ? renderSubmitted() : renderForm()}
    </Container>
  )
}

export default Contact

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
  Button,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  Container,
  CardHeader,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const pricingPlan = [
  {
    title: 'Social media paket 1',
    subtitle: 'Paket 1',
    price: '300.000',
    packages: [
      'Custom design request',
      '4 Postings per month',
      '2x Revision per posting',
      'Master file (PSD)',
      'Export PNG/JPG',
    ],
  },
  {
    title: 'Social media paket 2',
    subtitle: 'Paket 2',
    price: '700.000',
    packages: [
      'Custom design request',
      '8 Postings per month',
      '2x Revision per posting',
      'Master file (PSD)',
      'Export PNG/JPG',
    ],
  },
  {
    title: 'Company Website',
    subtitle: '',
    price: '',
    info: 'Hubungi Kami',
    packages: [
      'Website hosting di Indonesia',
      'SEO (Google) optimization',
      'Hosting and SSL cert (https)',
      'Domain management',
      'Free logo 1 option',
      'Custom design request',
      'Maximum 5 pages',
      '3x Revision',
    ],
  },
]

const Pricing = () => {
  const classes = useStyles()

  return (
    <Container
      maxWidth="lg"
      className={classes.container}
    >
      <Typography
        variant="h5"
        align="center"
        className={classes.title}
      >
        Layanan kami
      </Typography>
      <Typography
        variant="body2"
        align="center"
        className={classes.subTitle}
      >
        Cocok untuk mempromosikan profil, produk dan jasa perusahaan anda
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.grid}
        direction="row"
        spacing={4}
      >
        {pricingPlan.map(item => (
          <Grid
            key={item.title}
            item
            className={classes.gridItem}
            xs={12}
            sm={4}
          >
            <Card className={classes.card}>
              <CardHeader
                title={item.title}
                subheader={item.subtitle}
                className={classes.cardHeader}
                classes={{
                  content: classes.cardHeaderContent,
                  title: classes.cardHeaderTitle,
                  subheader: classes.cardHeaderSubtitle,
                }}
              />
              <CardContent
                style={{ height: '23rem' }}
              >
                {item.price && (
                  <div
                    className={classes.price}
                  >
                    <Typography
                      variant="body2"
                    >
                      Rp
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{ margin: '0 0.5rem' }}
                    >
                      {item.price}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: '0.8rem' }}
                    >
                      / month
                    </Typography>
                  </div>
                )}
                {item.info && (
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ margin: '1rem 0' }}
                  >
                    {item.info}
                  </Typography>
                )}
                {item.packages.map((pg) => (
                  <div
                    key={`${item.title}-${pg}`}
                    className={classes.package}
                  >
                    <CheckCircleIcon
                      className={classes.iconPackage}
                      color="primary"
                    />
                    <Typography
                      variant="caption"
                    >
                      {pg}
                    </Typography>
                  </div>
                ))}
              </CardContent>
              <CardActions
                disableSpacing
                style={{
                  justifyContent: 'center',
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  Pesan sekarang
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Pricing

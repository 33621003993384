export default (theme) => {
  return {
    container: {
      marginTop: '4rem',
    },
    grid: {
      marginTop: '2rem',
    },
    gridItem: {
      maxWidth: '20rem',
      minWidth: '18rem',
    },
    title: {
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    subTitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    card: {
      width: '100%',
      borderRadius: '1rem',
      border: '1px solid transparent',
      '&:hover': {
        border: `1px solid ${theme.color.secondary}`,
        '& $cardHeader': {
          background: theme.color.secondary,
        },

        '& $iconPackage': {
          color: theme.color.secondary,
        },

        '& $button': {
          background: theme.color.secondary,
        },
      },
    },
    cardHeader: {
      background: theme.color.primary,
      height: '5rem',
    },
    cardHeaderContent: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: theme.color.white,
    },
    cardHeaderTitle: {
      fontSize: '1.5rem',
    },
    cardHeaderSubtitle: {
      color: theme.color.white,
      lineHeight: '1rem',
    },
    button: {
      padding: '0.3rem 1.5rem',
      marginBottom: '1rem',
      fontSize: '0.875rem',
    },
    price: {
      display: 'flex',
      justifyContent: 'center',
      margin: '1rem 0',
    },
    package: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '0.7rem',
      marginTop: '0.5rem',
    },
    iconPackage: {
      fontSize: '1rem',
      marginRight: '1rem',
    },
  }
}
